import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Core/Seo"
import styled from "styled-components"
import ContentContainer from "../components/Core/ContentContainer"
import Hero from "../components/Project/Hero"
import Detail from "../components/Project/Detail"
import Gallery from "../components/Project/Gallery"
import RelatedProject from "../components/Project/RelatedProject"
import PreFooter from "../components/Core/PreFooter"
import breakpoints from "../styles/breakpoints"
import BlockContainer from "../components/Core/BlockContainer"

const PrefooterContainer = styled.div`
  @media(min-width: ${breakpoints.md}) {
    display: none;
  }
`

const project = (props) => {
  const path = props.location.pathname
  const page = props.data.page
  const projects = props.data.page.relatedProjects

  const {
    title,
    projectDate,
    hero,
    location,
    status,
    category,
    description,
    gallery,
  } = page
  const detailProps = {
    title,
    projectDate,
    location,
    status,
    category,
    description,
  }


  return (
    <Layout darkStyle={false}>
      <SEO
        fullTitle={true}
        path={path}
        title={page.metaTitle}
        image={(hero && hero.file) && hero.file.url}
        description={page.metaDescription}
      />
      <BlockContainer>
        <Hero hero={hero} title={title}/>
        <ContentContainer>
          <Detail {...detailProps} />
        </ContentContainer>
        <ContentContainer noPadding>
          <Gallery gallery={gallery}/>
        </ContentContainer>
        <ContentContainer smallPadding>
          <RelatedProject projects={projects}/>
        </ContentContainer>
        <ContentContainer>
          <PrefooterContainer>
            <PreFooter/>
          </PrefooterContainer>
        </ContentContainer>
      </BlockContainer>
    </Layout>
  )
}

export default project

export const pageQuery = graphql`
  query ProjectQuery($slug: String!) {
    page: contentfulProject(slug: { eq: $slug }) {
      title
      metaTitle
      metaDescription
      slug
      category {
        title
      }
      description {
        json
      }
      hero {
        file {
          url
        }
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      status
      location
      projectDate(formatString: "YYYY")
      gallery {
        fullWidth
        image {
          fluid(maxWidth: 1920, quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      relatedProjects {
        title
        slug
        category {
          title
        }
        thumbnails {
          fluid(maxWidth: 1920, quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

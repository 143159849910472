import React from "react"
import styled from "styled-components"
import { Col, Container, Row } from "styled-bootstrap-grid"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Img from "gatsby-image"
import { Link } from "gatsby"

const ImgStyled = styled(Img)`
  height: 243px;
  @media (min-width: ${breakpoints.sm}) {
    height: 343px;
  }
  @media (min-width: ${breakpoints.md}) {
    height: 394px;
  }
`

const ImageContainer = styled.div`
  margin-bottom: 20px;
`

const Category = styled.div`
  ${fonts.ralewayMedium};
  color: ${colors.lightGold};
  letter-spacing: 0.28px;
  font-size: 14px;
  margin-bottom: 8px;
  @media (min-width: ${breakpoints.md}) {
    font-size: 16px;
    letter-spacing: 0.32px;
    margin-bottom: 6px;
  }
`

const ProjectTitle = styled.h3`
  ${fonts.ralewayRegular};
  color: ${colors.darkGrey};
  font-size: 16px;
  letter-spacing: 4.8px;
  text-transform: uppercase;
  line-height: 26px;
  @media (min-width: ${breakpoints.md}) {
    font-size: 24px;
    letter-spacing: 7.2px;
    line-height: 30px;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const ProjectCard = ({project}) => {
  const { slug, thumbnails, category: {title: category}, title} = project;
  
  return (
    <React.Fragment>
      <StyledLink to={`/projet/${slug}`}>
        <ImageContainer>
          <ImgStyled fluid={thumbnails[0].fluid} />
        </ImageContainer>
        <Category>{category}</Category>
        <ProjectTitle>{title}</ProjectTitle>
      </StyledLink>
    </React.Fragment>
  )
}

export default ProjectCard

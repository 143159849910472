import React from 'react'
import styled from "styled-components"
import { Col, Container, Row } from "styled-bootstrap-grid"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import ProjectCard from "./ProjectCard"

const StyledContainer = styled(Container)`
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 120px;
  }
`

const Title = styled.h2`
  ${fonts.ralewayRegular};
  color: ${colors.lightGold};
  font-size: 18px;
  letter-spacing: 10.8px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 24px;
  line-height: 30px;
  @media (min-width: ${breakpoints.md}) {
    font-size: 30px;
    letter-spacing: 9px;
    margin-bottom: 40px;
    line-height: normal;
  }
`

const ColStyled = styled(Col)`
  margin-bottom: ${props => props.last ? "0px" : "40px"};
  @media (min-width: ${breakpoints.md}) {
    padding: ${props => props.last ? "0 0 0 12.5px" : "0 12.5px 0 0 " };
    margin-bottom: 0;
  }
`

const RelatedProject = ({projects}) => {
  return (
    <StyledContainer>
      <Title>Autres Projets</Title>
      <Row>
        {projects.map((project, index) => {
          return (
            <ColStyled md={6} last={index === 1} key={index}>
              <ProjectCard project={project} />
            </ColStyled>
          )
        })}
      </Row>
    </StyledContainer>
  )
}

export default RelatedProject


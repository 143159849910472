import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from "styled-bootstrap-grid";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";
import Img from "gatsby-image";

const ImgStyled = styled(Img)`
  width: 100%;
  height: 420px;
  @media (min-width: ${breakpoints.md}) {
    height: auto;
    max-height: 800px;
  }

`

const HeroContainer = styled.div`
  max-height: 420px;
  @media (min-width: ${breakpoints.md}) {
    max-height: 800px;
  }
`

const Hero = ({hero, title}) => {
  return (
    <div>
      {hero && <ImgStyled fluid={hero.fluid} loading={"eager"} alt={title} />}
    </div>
  )
}

export default Hero

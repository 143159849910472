import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Container } from "styled-bootstrap-grid"
import breakpoints from "../../styles/breakpoints"
import Img from "gatsby-image"
import Popup from "./Popup"
import InView from "./InView"
import { isBrowser, setBodyOverflow } from "../../services/browser/browser"

const ImageContainer = styled.div`
  width: 100% !important;
  height: auto !important;
  -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
  page-break-inside: avoid; /* Firefox */
  break-inside: avoid; /* IE 10+ */
  margin: 10px 0;
  cursor: pointer;
  max-height: 752px;
  max-height: ${(props) => (props.width ? "752px" : "828px")};
  @media (min-width: ${breakpoints.md}) {
    margin: 0;
    margin-bottom: 20px;
  }
`

const StyledImg = styled(Img)`
  max-height: ${(props) => (props.width ? "752px" : "828px")};
  img {
    max-height: ${(props) => (props.width ? "752px" : "828px")};
  }
`

const MosaicContainer = styled.div`
  @media (min-width: ${breakpoints.md}) {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    column-gap: 24px;
    height: ${props => props.height ? "630px" : "auto"};
  }
  @media (min-width: ${breakpoints.lg}) {
    height: ${props => props.height ? "850px" : "auto"};
  }
`

const GalleryContainer = styled.div`
  margin-bottom: 100px;
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 150px;
  }
`

const Gallery = ({ gallery }) => {
  const [selected, setSelected] = useState(0)
  const [popupOpen, setPopupOpen] = useState(false)
  const fullWidth = []
  const halfWidth = []

  useEffect(() => {
    if (isBrowser()) {
      setBodyOverflow(popupOpen);
    }
  }, [popupOpen])

  gallery &&
    gallery.filter((image, index) =>
      image.fullWidth
        ? fullWidth.push({ image, index })
        : halfWidth.push({ image, index })
    )

  const handleClick = (index) => {
    setPopupOpen(!popupOpen)
    setSelected(index)
  }
  return (
    <GalleryContainer>
      <Container>
        {fullWidth.map((image, index) => {
          return (
            <React.Fragment key={index}>
              <InView threshold={0.1} opacity={"0.6"} transform>
                <ImageContainer
                  width={image.fullWidth}
                  onClick={() => handleClick(image.index)}
                >
                  <StyledImg
                    fluid={image.image.image.fluid}
                    width={image.fullWidth}
                  />
                </ImageContainer>
              </InView>
            </React.Fragment>
          )
        })}
        <InView threshold={0.1} opacity={"0.6"} transform>
          <MosaicContainer height={halfWidth.length <= 2 ? 1 : 0}>
            {halfWidth.map((image, index) => {
              return (
                <ImageContainer
                  width={image.fullWidth}
                  key={index}
                  onClick={() => handleClick(image.index)}
                >
                  <StyledImg
                    fluid={image.image.image.fluid}
                    width={image.fullWidth}
                  />
                </ImageContainer>
              )
            })}
          </MosaicContainer>
        </InView>
      </Container>
      <Popup
        gallery={gallery}
        selected={selected}
        setSelected={setSelected}
        open={popupOpen}
        close={setPopupOpen}
      />
    </GalleryContainer>
  )
}

export default Gallery

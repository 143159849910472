import React, {useEffect, useRef, useState} from "react"
import styled from "styled-components"
import { Col, Container, Row } from "styled-bootstrap-grid"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import InView from "./InView"

const StyledContainer = styled(Container)`
  margin-top: 50px;
  margin-bottom: 70px;
  padding: 0 16px;
  @media (min-width: ${breakpoints.md}) {
    padding: 0;
    margin-top: 99px;
    margin-bottom: 118px;
  }
`

const Category = styled.h2`
  ${fonts.galvjiRegular};
  color: ${colors.lightGold};
  font-size: 16px;
  letter-spacing: 0.03px;
  @media (min-width: ${breakpoints.md}) {
    font-size: 18px;
    letter-spacing: 0.04px;
  }
`

const Title = styled.h1`
  ${fonts.ralewayRegular};
  color: ${colors.darkGrey};
  font-size: 24px;
  letter-spacing: 7.2px;
  line-height: 34px;
  margin: 10px 0;
  text-transform: uppercase;
  overflow-wrap: break-word;
  @media (min-width: ${breakpoints.md}) {
    font-size: 48px;
    letter-spacing: 14.4px;
    line-height: 62px;
  }
`

const ProjectDetail = styled.ul`
  ${fonts.galvjiRegular};
  color: ${colors.grey};
  font-size: 14px;
  letter-spacing: 0.03px;
  display: flex;
  margin-bottom: 30px;
  li {
    position: relative;
    padding-left: 14px;
    ::before {
      content: "";
      position: absolute;
      width: 4px;
      height: 4px;
      background: ${colors.lightGold};
      border-radius: 50%;
      top: calc(50% - 2px);
      left: 5px;
    }
    :first-child {
      padding-left: 0;
      ::before {
        content: "";
        position: relative;
      }
    }
    @media (min-width: ${breakpoints.md}) {
      li {
        font-size: 14px;
      }
    }
  }
`

const Description = styled.div`
  ${fonts.galvjiRegular};
  color: ${colors.grey};
  font-size: 14px;
  letter-spacing: 0.03px;
  line-height: 21px;
  max-width: 744px;
  @media (min-width: ${breakpoints.md}) {
    font-size: 16px;
    line-height: 24px;
  }
`
const Detail = (props) => {
  const {
    title,
    projectDate,
    location,
    status,
    description,
    category: { title: category },
  } = props
  return (
      <StyledContainer>
        <Row>
          <Col md={10} mdOffset={1} lg={8} lgOffset={2}>
            <InView threshold={0.1} opacity={"0"} transform>
              <Category>{category}</Category>
              <Title>{title}</Title>
              <ProjectDetail>
                <li>Terminé</li>
                <li>{projectDate}</li>
                <li>{location}</li>
              </ProjectDetail>
              <Description>
                {description && documentToReactComponents(description.json)}
              </Description>
            </InView>
          </Col>
        </Row>
      </StyledContainer>
  )
}

export default Detail

import React, { useRef } from "react"
import ReactPopup from "reactjs-popup"
import styled from "styled-components"
import { Col, Container, Row } from "styled-bootstrap-grid"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import Img from "gatsby-image"
import Slider from "react-slick/lib"
import Left from "../../resources/img/ui/popup-left.svg"
import Right from "../../resources/img/ui/popup-right.svg"
import Close from "../../resources/img/ui/popup-close.svg"
import CloseWhite from "../../resources/img/ui/popup-close-white.svg"
import LeftWhite from "../../resources/img/ui/popup-left-white.svg"
import RightWhite from "../../resources/img/ui/popup-right-white.svg"

const contentStyle = {
  width: "100%",
  height: "100%",
  margin: "auto",
  boxSizing: "border-box",
  overflow: "hidden",
  position: "relative",
}

const contentStyleMobile = {
  width: "100%",
  height: "100%",
  margin: "auto",
  overflow: "auto",
  position: "relative",
}

const overlayStyle = {
  overflowY: "auto",
  outline: "none",
  background: colors.white,
}

const StyledSlider = styled(Slider)`
  div {
    height: calc(100vh - 144px) !important;
  }
  @media (min-width: ${breakpoints.md}) {
    div {
      height: 100vh !important;
    }
  }
`

const StyledImg = styled(Img)`
  img {
    object-fit: contain !important;
  }
  @media (min-width: ${breakpoints.md}) {
    img {
      object-fit: cover !important;
    }
  }
`

const ImageContainer = styled.div`
  align-items: center;
  border: 1px solid transparent;
  height: 100% !important;
  cursor: grab;
  @media (min-width: ${breakpoints.md}) {
    height: 100vh !important;
  }
`

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: ${props => props.desktop ? "none" : "block"};
  cursor: pointer;
  padding: 20px;
  
  @media (min-width: ${breakpoints.md}) {
    display: ${props => props.desktop ? "block" : "none"};
    top: 0px;
    right: 0;
  }
`

const PageIndex = styled.div`
  ${fonts.galvjiRegular};
  font-size: 14px;
  color: ${colors.grey};
  height: 100%;
  display: ${props => props.desktop ? "none" : "flex"};
  justify-content: center;
  @media (min-width: ${breakpoints.md}) {
    display: ${props => props.desktop ? "block" : "none"};
    position: absolute;
    bottom: 17px;
    right: 20px;
    color: ${colors.white};
    height: auto;
  }
`
const PageButton = styled.div`
  height: 100%;
  justify-content: ${(props) => (props.left ? "flex-start" : "flex-end")};
  display: ${props => props.desktop ? "none" : "flex"};
  cursor: pointer;
  @media (min-width: ${breakpoints.md}) {
    padding: 20px;
    display: ${props => props.desktop ? "block" : "none"};
    top: 50%;
    position: absolute;
    ${props => props.left? "left: 0" : "right: 0"};
    height: auto;
  }
`

const Mobile = styled.div`
  height: 72px;
  width: 100%;
  display: flex;
  align-items: center;
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const RowStyled = styled(Row)`
  width: 100%;
  padding: 0 30px;
`

const Popup = ({ gallery, selected, setSelected, open, close }) => {
  const sliderRef = useRef(null)

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    swipe: true,
    swipeToSlide: true,
    draggable: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
    initialSlide: selected,
    adaptiveHeight: false,
    centerMode: false,
    afterChange: (current, next) => setSelected(current),
  }

  const getContentStyle = () => {
    if (typeof window === "undefined") {
      return
    }
    if (window.innerWidth >= 768) {
      return contentStyle
    }
    return contentStyleMobile
  }

  const next = () => {
    const slider = sliderRef.current
    slider.slickNext()
  }

  const prev = () => {
    const slider = sliderRef.current
    slider.slickPrev()
  }

  const galleryLength = gallery ? gallery.length : 1;

  return (
    <ReactPopup
      open={open}
      contentStyle={getContentStyle()}
      overlayStyle={overlayStyle}
    >
      <Mobile>
        <CloseButton onClick={() => close(false)}>
          <img src={Close} alt="popup close" />
        </CloseButton>
      </Mobile>
      <StyledSlider {...settings} ref={sliderRef}>
        {gallery && gallery.map((image, index) => {
          return (
            <ImageContainer key={index}>
              <StyledImg fluid={image.image.fluid} />
            </ImageContainer>
          )
        })}
      </StyledSlider>
      <Mobile>
        <RowStyled>
          <Col xs={3} sm={3}>
            <PageButton left onClick={() => prev()}>
              <img src={Left} />
            </PageButton>
          </Col>
          <Col xs={6} sm={6}>
            <PageIndex center>
              {selected + 1} / {galleryLength}
            </PageIndex>
          </Col>
          <Col xs={3} sm={3}>
            <PageButton onClick={() => next()}>
              <img src={Right} />
            </PageButton>
          </Col>
        </RowStyled>
      </Mobile>
      <CloseButton desktop onClick={() => close(false)}>
        <img src={CloseWhite} alt="popup close" />
      </CloseButton>
      <PageButton left desktop onClick={() => prev()}>
        <img src={LeftWhite} alt="previouse" />
      </PageButton>
      <PageButton desktop onClick={() => next()}>
        <img src={RightWhite} alt="Next" />
      </PageButton>
      <PageIndex desktop>
        {selected + 1} / {galleryLength}
      </PageIndex>
    </ReactPopup>
  )
}

export default Popup
